
import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-md">
      <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
      <p className="text-gray-700 mb-4"><strong>Effective Date:</strong> [03-09-2024]</p>

      <h2 className="text-2xl font-semibold mb-2">1. Introduction</h2>
      <p className="text-gray-700 mb-4">
        Welcome to Vaymp. This Privacy Policy explains how we collect, use, and protect your information when you use our website, <a href="https://www.vaymp.com" className="text-blue-500">www.vaymp.com</a> (the "Site"). By using the Site, you agree to the collection and use of information in accordance with this policy.
      </p>

      <h2 className="text-2xl font-semibold mb-2">2. Information We Collect</h2>
      <p className="text-gray-700 mb-4">
        <strong>Personal Data:</strong> To verify your account, we collect and store your profile information and email address.
      </p>

      <h2 className="text-2xl font-semibold mb-2">3. How We Use Your Information</h2>
      <ul className="list-disc list-inside text-gray-700 mb-4">
        <li>Verify your account and provide account-related services.</li>
        <li>Communicate with you regarding your account and updates to the Site.</li>
      </ul>

      <h2 className="text-2xl font-semibold mb-2">4. Data Security</h2>
      <p className="text-gray-700 mb-4">
        We implement appropriate technical and organizational measures to ensure the security of your personal information. However, no method of transmission over the Internet or method of electronic storage is 100% secure.
      </p>

      <h2 className="text-2xl font-semibold mb-2">5. Data Sharing</h2>
      <p className="text-gray-700 mb-4">
        We do not sell or rent your personal information to third parties. We may share your information with service providers who assist us in operating the Site, but they are obligated to keep your information confidential.
      </p>

      <h2 className="text-2xl font-semibold mb-2">6. Your Rights</h2>
      <ul className="list-disc list-inside text-gray-700 mb-4">
        <li>Access and update your personal information.</li>
        <li>Request the deletion of your personal information, subject to legal obligations.</li>
      </ul>

      <h2 className="text-2xl font-semibold mb-2">7. Changes to This Privacy Policy</h2>
      <p className="text-gray-700 mb-4">
        We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
      </p>

      <h2 className="text-2xl font-semibold mb-2">8. Contact Us</h2>
      <p className="text-gray-700 mb-4">
        If you have any questions about this Privacy Policy, please contact us at:
      </p>
      <p className="text-gray-700 mb-4">
        <strong>Email:</strong> <a href="vaympforyou@gmail.com" className="text-blue-500">vaympforyou@gmail.com</a>
      </p>
      <p className="text-gray-700">
        <strong>Address:</strong> [Phagwara, punjab, Bharat]
      </p>
    </div>
  );
};

export default PrivacyPolicy;