import React, { useState } from 'react';
import { Link,useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { server } from '../server';
import styles from '../styles/styles';
const PasswordReset = () => {
  const { token } = useParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Submitting password reset form...'); // Debugging statement
    if (password !== confirmPassword) {
      toast.error("Passwords isn't matching",{
        autoClose:2000, // Duration in milliseconds
        });
      return;
    }
    setLoading(true);
    try {
      const res = await axios.put(`${server}/user/password/reset/${token}`, {
        password,
        confirmPassword, // Include confirmPassword in the request body
      });
      toast.success(res.data.message,{
        autoClose:1000, // Duration in milliseconds
        });
    } catch (err) {
      toast.error(err.response.data.message,{
        autoClose:1000, // Duration in milliseconds
        });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Reset your password
        </h2>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                New password
              </label>
              <div className="mt-1">
                <input
                  type="password"
                  name="password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="confirm-password"
                className="block text-sm font-medium text-gray-700"
              >
                Confirm password
              </label>
              <div className="mt-1">
                <input
                  type="password"
                  name="confirm-password"
                  required
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
              <div className={`${styles.noramlFlex} w-full pt-2 mt-2`}>
                <h4>Password successfully reset?</h4>
                <Link to="/login" className="text-blue-600 pl-2 ">
                  Sign in
                </Link>
              </div>
            </div>
            <div>
              {loading ? (
                <button
                  type="button"
                  className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600"
                  disabled
                >
                  Resetting...
                </button>
              ) : (
                <button
                  type="submit"
                  className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
                >
                  Reset password
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;